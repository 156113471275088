@import "./../../design-tokens/scss-variables.scss";

.spot-direction {
  display: inline-block;
  //margin-right: 15px;
  text-align: center;
  width:50px;
  height:50px;
  //background-color: #eee;
  position: relative;
  font-size: 8px;
  .conic {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    position: absolute;
    right:10px;
    top:10px;
  }
  .n {
    position: absolute;
    right:21px;
    top:0px;
  }
  .s {
    position: absolute;
    right:22px;
    bottom:0px;
  }
  .w {
    position: absolute;
    left:2px;
    top:20px;
  }
  .e {
    position: absolute;
    right:5px;
    top:20px;
  }

}


.spot-direction-text {
  display:none;
  margin-top:20px;
  @include font-mini;
}

