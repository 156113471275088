@import "./design-tokens/scss-variables.scss";

.root {
  background-color: $app-bg;
}

.container {
  @include app-width;
  display: flex;
  flex-direction: column;
  margin-bottom: $nav-height;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top:0;
}

.bottom-margin {
  margin-bottom:40px;
}

.menu {
  display: inline-block;
  flex-direction: row;
  background-color: $menu-color;
  li {
    margin-bottom: 20px;
    overflow: hidden;
    display: inline-block;
    padding: 10px 30px;
    border: 1px solid gray;
    margin-right: 5px;
  }
}
.main {
}

.main-index {
  @include app-padding;
}

.site-heading {
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $nav-bg;
  @include app-padding;
  padding-top:15px;
  padding-bottom:15px;
  height:60px;
  border-bottom: 1px solid $gray3;
  .icon.kitesurfing {
    width: 35px;
    height: 35px;
    margin-left: 8px;
  }
  h1 {
    @include font-medium;
    font-size: 22px;
    margin:0 0 0 15px;
  }
}
.tagline {
  font-size: 14px;
  margin-top: -23px;
}

.ledger-container {
  margin-top: 60px;
  h2 {
    @include flex-column;
  }
  h3 {
    margin-top: 25px;
    margin-bottom: 2px;
    font-size: 13px;
  }
  .text {
    font-size: 14px;
    margin-top:10px;
  }
  .ledger {
    @include flex-row;
    justify-content: space-between;
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .wrong-direction {
    @include flex-row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 7px 7px 0 7px;
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .wrong-text, .good-text, .align-left {
    @include flex-row;
  }
  .gust-text {
    color: $gray6;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    width: 50px;
  }

}

.spot-direction-ledger {
  margin-top:10px;
}

.debug-spot-direction {
  margin-top:30px;
  .spot-direction-text {
    display: block;
  }
}
