@import "./../../design-tokens/scss-variables.scss";

.summary-page{
  .summary-spot {
    display:none;
  }
  &.kitesurf {
    .summary-spot.kitesurf {
      display:block;
    }
  }
  &.snowkite {
    .summary-spot.snowkite {
      display:block;
    }
  }
  &.special {
    .summary-spot.special {
      display:block;
    }
  }
}