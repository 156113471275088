$gray1: #f9f9f9;
$gray12: #f5f5f5;
$gray2: #e5e5e5;
$gray3: #ccc;
$gray4: #b2b2b2;
$gray5: #999;
$gray6: #7f7f7f;
$gray7: #666;
$gray8: #4c4c4c;
$gray9: #333;
$gray10: #191919;

$wind0: #203763;
$wind1: #203763;
$wind2: #203763;
$wind3: #203763;
$wind4: #1a6cb2;
$wind5: #1a6cb2;
$wind6: #1a6cb2;
$wind7: #4f9e4f;
$wind8: #4f9e4f;
$wind9: #4f9e4f;
$wind10: #f09100;
$wind11: #f09100;
$wind12: #f09100;
$wind13: #f84400;
$wind14: #f84400;
$wind15: #f84400;
$wind16: #9d013d;
$wind17: #9d013d;
$wind18: #9d013d;
$wind19: #752222;
$wind20: #752222;
$wind21: #752222;
$wind22: #752222;

$menu-color: #d2ecce;

$app-bg:#fffff0;
$app-bg:#bff4ff;
$app-bg:#e6faff;
$app-bg:#fff;


$nav-bg:#f1f8f1;



$nav-footer:#a2a28c;

$nav-height: 50px;



@mixin app-width {
  max-width:428px;
}
@mixin app-padding {
  padding-left: 15px;
  padding-right: 15px;
}
@mixin top-bottom-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

@mixin pop-shadow {
  box-shadow:
          0 2.8px 2.2px rgba(0, 0, 0, 0.034),
          0 6.7px 5.3px rgba(0, 0, 0, 0.048),
          0 12.5px 10px rgba(0, 0, 0, 0.06),
          0 22.3px 17.9px rgba(0, 0, 0, 0.072),
          0 41.8px 33.4px rgba(0, 0, 0, 0.086),
          0 100px 80px rgba(0, 0, 0, 0.12);
}
@mixin font-big {
  font-size:2.5em;
  line-height: 1;
  letter-spacing: -2px;
}
@mixin font-medium {
  font-size:29px;
  line-height: 1;
}
@mixin font-small {
  font-size:16px;
  line-height: 1;
  font-weight: 700;
}
@mixin font-mini {
  font-size:13px;
  line-height: 1;
  font-weight: 700;
}
@mixin flex-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@mixin flex-column {
  display: flex;
  flex-direction:column;
  align-items: flex-start;
}
@mixin wind-colors {
  &.w0 {background-color:$wind0;}
  &.w1 {background-color:$wind1;}
  &.w2 {background-color:$wind1;}
  &.w3 {background-color:$wind3;}
  &.w4 {background-color:$wind4;}
  &.w5 {background-color:$wind5;}
  &.w6 {background-color:$wind6;}
  &.w7 {background-color:$wind7;}
  &.w8 {background-color:$wind8;}
  &.w9 {background-color:$wind9;}
  &.w10 {background-color:$wind10;}
  &.w11 {background-color:$wind11;}
  &.w12 {background-color:$wind12;}
  &.w13 {background-color:$wind13;}
  &.w14 {background-color:$wind14;}
  &.w15 {background-color:$wind15;}
  &.w16 {background-color:$wind16;}
  &.w17 {background-color:$wind17;}
  &.w18 {background-color:$wind18;}
  &.w19 {background-color:$wind19;}
  &.w20 {background-color:$wind20;}
}




















