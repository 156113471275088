@import "./../../design-tokens/scss-variables.scss";

.nav-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height:$nav-height;
  z-index:10;
  background-color: $nav-bg;
  border-top: 1px solid $gray3;
  @include flex-row;
  align-content: space-between;
  @include app-padding;
  @include app-width;
  a {
    display: block;
    width:33%;
    flex-grow:1;
  }
  svg {
    fill: $gray9;
    width:30px;
  }
}