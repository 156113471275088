@import "./../../design-tokens/scss-variables.scss";

.settings {
  @include app-padding;
  @include flex-column;
  width:100%;
  ul {
    width:100%;
  }


  .clear-cache, .reset-settings {
    margin-top: 10px;
    padding: 7px 20px;
    font-size: 18px;
    cursor: pointer;
  }
}