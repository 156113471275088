@import "./../../design-tokens/scss-variables.scss";

.spinner {
  padding: 30px;
  display: flex;
  justify-content: center;}
.spin {
  border: 5px solid $gray2;
  border-top: 5px solid $wind7;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 700ms linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.debug-spot-direction {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
}
.spot-direction{
  display: flex;
  flex-direction:column;
  .spot-direction-text{
    display: none;
  }
}
