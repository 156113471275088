@import "./../../design-tokens/scss-variables.scss";

.spot {
  display: flex;
  flex-direction: column;
  .spot-head {
    @include flex-row;
    justify-content: space-between;
    height: 50px;
    background-color: $gray12;
    border-top: 1px solid $gray4;
    border-bottom: 1px solid $gray3;
    @include app-padding;
    .left {
      display: flex;
      flex-direction: row;
      align-items:center;
      .symbol {
        background-color: black;
        color:white;
        font-size: 1.4em;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include pop-shadow;
        @include wind-colors;
      }
      .name {
       @include font-big;
      }
    }
    .right {
      width:140px;
      @include font-small;
      @include flex-row;
      justify-content: flex-end;
      .weekday {
        margin-right:10px;
      }
    }
  }

  .spot-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $app-bg;
    height:75px;
    @include app-padding;
    @include top-bottom-padding;
    .wind{
      @include font-big;
    }
    .gust{
      color: $gray5;
      @include font-big;
    }
    .icon {
      width:50px;
    }
    .temp {
      @include font-medium;
    }

  }
}

