@import "./../../design-tokens/scss-variables.scss";

.detail-page {
  position: relative;
  &.showOnlyDaylight {
    .detail-hour.nolight {
      display:none;
    }
  }
}


.detail-head-top{
  z-index: 10;
  background-color: $gray2;
  @include app-padding;
  padding-top: 10px;
}
.detail-head {
  position: sticky;
  top: 0px;
  z-index: 10;
  background-color: $gray2;
  margin-bottom: 10px;
  border-bottom: solid 1px $gray3;
  @include app-padding;
  .bottom{
    @include flex-row;
    justify-content: space-between;
    .name-expand {
      @include flex-row;
      @include font-big;
      cursor: pointer;
      padding-right: 10px;
      .expand-more {
        margin-left: 5px;
        font-size: 1.4em;
        transform: rotate(0);
        transition: 0.2s ease-out;
      }
      &.active {
        margin-bottom: 20px;
        .expand-more {
          transform: rotate(180deg);
        }
      }

    }
  }
  .expanded {
    @include flex-row;
    @include font-small;
    //padding-top: 10px;
    //padding-bottom: 10px;
    max-height: 0;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0;

    //transition: margin-top 0.6s ease-out;
    //transition: margin-bottom 0.6s ease-out;
    //transition: max-height 0.3s ease-out;
    //transition: all 300ms ease;
    //transition-property: margin-top, margin-bottom, max-height;
    //transition: 0.5s ease-out;
    transition: max-height 0.5s ease, margin-top 0.3s ease 0.1s, margin-bottom 0.5s ease 0.1s;

    &.active {
      max-height: 350px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    a {
      text-decoration: underline;
      line-height: 20px;
    }
  }
}




.new-day{
  position: sticky;
  top:50px;
  z-index: 10;
}

.day {
 background-color: $gray2;
  border-top: $gray3;
  color: $gray8;
  display: flex;
  justify-content: flex-start;
  @include font-mini;
  height: 20px;
  align-items: center;
  padding-left: 10px;
  border-top: 1px solid $gray3;
  .weekday {
    margin-right:10px
  }
}

.no-more-daylight {
  @include flex-row;
  @include app-padding;
  @include font-mini;
  display:none;
  font-weight: 400;
  height: 30px;
  border-top: 1px solid $gray3;
  &.active {
    display:flex;
  }
}

.detail-hour{
  background-color: white;
}
.hourly{
  display:flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  border-top: 1px solid $gray3;
  @include font-small;
  @include app-padding;

  .dir svg{
    width:17px;
  }
  .part {
    @include flex-row;
  }
  .part-wind {
    width:160px;
    display: flex;
    justify-content: flex-start;
    .dir {
      width:43px;
    }
    .wind{
     margin-right: 5px;
    }
    .gust{
      display:flex;
      color: $gray6;
      @include font-small;
      margin-right: 5px;
      &.g0 {
        display:none;
      }
    }
    .unit{
      @include font-mini;
    }
  }

  //.wind-area {
  //  display: flex;
  //  justify-content: space-between;
  //  width: 120px;
  //  .wind{
  //    @include font-small;
  //    width:65px;
  //    span {
  //      font-size: 12px;
  //      font-weight: 400;
  //    }
  //  }
  //  .gust{
  //    color: $gray5;
  //    @include font-small;
  //    width: 50px;
  //  }
  //}


  .hour {
    margin-right: 10px;
  }
  .icon {
    width:30px;
    margin-right: 10px;
  }
  .temp {

  }

  .wind-bar{
    @include flex-row;
    width:100px;
    height:17px;
    border: solid 1px $gray2;
    border-radius: 16px;
    border-left: none;
    .wind{
      height:17px;
      @include wind-colors;
      border-top-left-radius:8px;
      border-bottom-left-radius:8px;
    }
    .gust {
      height:17px;
      background-color: $gray3;
    }
  }

}